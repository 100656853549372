import React from 'react';
import { Alert, BackTop, Card, Col, Row, Space, Typography } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface MatchParams {
  ruleid: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const { Paragraph, Title } = Typography;

const listStyle: React.CSSProperties = {
  listStyleType: 'disc',
  listStylePosition: 'inside',
};

const Rules: React.FC<MatchProps> = ({ match }) => {
  const { formatMessage } = useIntl();

  return (
    <Row justify="center">
      <Col>
        <BackTop />
        <Space direction="vertical" style={{ maxWidth: 720 }}>
          <Alert type="warning" message={formatMessage({ id: 'rulePage.changeRules.t241005' })} />
          <Card size="small" title={formatMessage({ id: `rulePage.voteRuleTitle` })}>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1, 2, 3, 4, 5].map((i, index) => (
                  <li key={index.toString()}>{formatMessage({ id: `rulePage.voteRules.${i}` })}</li>
                ))}
              </ul>
            </Paragraph>
          </Card>
          <Card size="small" title={formatMessage({ id: `rulePage.voteSystemTitle` })}>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.voteSystem.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
          </Card>
          <Card size="small" title={formatMessage({ id: `rulePage.submitRuleTitle` })}>
            <Paragraph>
              <ul style={listStyle}>
                <li style={{ color: 'red' }}>
                  {formatMessage({ id: `rulePage.submitRulesImportant` })}
                </li>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.submitRules.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
          </Card>
          <Card size="small" title={formatMessage({ id: `rulePage.keyMisalignmentTitle` })}>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.keyMisalignmentRules.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
          </Card>
          <Card size="small" title={formatMessage({ id: `rulePage.revisionRuleTitle` })}>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.revisionRules.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
          </Card>
          <Card size="small" title={formatMessage({ id: `rulePage.stellawayDescriptionTitle` })}>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1, 2, 3, 4, 5, 6].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.stellawayDescriptions.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
          </Card>
          <Card size="small" title={formatMessage({ id: `rulePage.uploaderDescription` })}>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1, 2, 3, 4, 5].map((i, index) => {
                  if (i === 1)
                    return (
                      <li key={index.toString()}>
                        {formatMessage({ id: `rulePage.uploaderDescriptionsSp.0` })}
                        <a href="https://bms-score-viewer.pages.dev/">
                          https://bms-score-viewer.pages.dev/
                        </a>
                        {formatMessage({ id: `rulePage.uploaderDescriptionsSp.1` })}
                      </li>
                    );
                  if (i > 1)
                    return (
                      <li key={index.toString()}>
                        {formatMessage({
                          id: `rulePage.uploaderDescriptions.${i - 1}`,
                        })}
                      </li>
                    );
                  return (
                    <li key={index.toString()}>
                      {formatMessage({ id: `rulePage.uploaderDescriptions.${i}` })}
                    </li>
                  );
                })}
              </ul>
            </Paragraph>
          </Card>
          <Card size="small" title={formatMessage({ id: `rulePage.copyrightTitle` })}>
            <Title level={5}>{formatMessage({ id: `rulePage.copyright1Title` })}</Title>
            <Paragraph>
              <ul style={listStyle}>
                {[0].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.copyright1s.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
            <Title level={5}>{formatMessage({ id: `rulePage.copyright2Title` })}</Title>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.copyright2s.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
            <Title level={5}>{formatMessage({ id: `rulePage.copyright3Title` })}</Title>
            <Paragraph>
              <ul style={listStyle}>
                {[0, 1].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `rulePage.copyright3s.${i}` })}
                  </li>
                ))}
                <a href="https://docs.google.com/spreadsheets/d/1cfaVTNuYaZ7jXrhNiI-0LCba-Ueo7GOgLd7BKMJPGqg/edit#gid=0">
                  {formatMessage({ id: `rulePage.copyright3link` })}
                </a>
                <li key="2">
                  {formatMessage({ id: `rulePage.copyright3sp.0` })}
                  <Link to="/contact">{formatMessage({ id: `rulePage.copyright3sp.1` })}</Link>
                  {formatMessage({ id: `rulePage.copyright3sp.2` })}
                </li>
              </ul>
            </Paragraph>
            <Title level={5}>{formatMessage({ id: `rulePage.copyright4Title` })}</Title>
            <Paragraph>
              <p>
                {formatMessage({ id: `rulePage.copyright4sp.0` })}
                <Link to="/contact">{formatMessage({ id: `rulePage.copyright4sp.1` })}</Link>
                {formatMessage({ id: `rulePage.copyright4sp.2` })}
              </p>
            </Paragraph>
          </Card>
        </Space>
      </Col>
    </Row>
  );
};

export default Rules;
